// CustomButton.js
import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  background-color: black;
  color: lime;
  border: 2px solid lime;
  border-radius: 5px;
  font-family: Courier New;
  font-size: 16px;
  padding: 10px 20px;
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(0, 255, 0, 0.3);
  transition: all 0.3s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transform: scale(1);

  &:hover {
    background-color: lime;
    color: black;
    box-shadow: 0 2px 4px rgba(0, 255, 0, 0.5);
    transform: scale(1.05);
  }

  @media (max-width: 480px) {
    font-size: 14px;
    padding: 8px 16px;
  }
`;

const Icon = styled.i`
  margin-left: 8px;
  font-size: 18px;
`;

const CustomButton = ({ children, icon, onClick }) => {
  return (
    <StyledButton onClick={onClick}>
      {children}
      {icon && <Icon className={`fa fa-${icon}`} />}
    </StyledButton>
  );
};

export default CustomButton;
