import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MatrixBackground from './MatrixBackground';
import styled, { keyframes } from 'styled-components';
import Header from './Header';


const MobileWarningTop = styled.div`
  display: none;
  background-color: #1b1f23;
  color: white;
  font-family: Courier New;
  font-size: 14px;
  font-weight: bold;
  padding: 5px;
  text-align: left;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;

  @media (max-width: 480px) {
    display: block;
  }
`;

const MobileWarningBottom = styled(MobileWarningTop)`
  top: unset;
  bottom: 0;
`;



const FullScreenContainer = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
`;

const glitchEffect = keyframes`
  0%, 100% {
    clip: rect(44px, 9999px, 56px, 0);
  }
  10%, 90% {
    clip: rect(17px, 9999px, 40px, 0);
  }
  20%, 80% {
    clip: rect(65px, 9999px, 90px, 0);
  }
  30%, 50%, 70% {
    clip: rect(20px, 9999px, 50px, 0);
  }
  40%, 60% {
    clip: rect(30px, 9999px, 70px, 0);
  }
`;

const Container = styled.div`
  padding: 25px;
  border: 2px solid lime;
  box-sizing: border-box;
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(red, red);
    opacity: 0;
    animation: ${glitchEffect} 5s infinite;
    clip: rect(0, 900px, 0, 0);
  }

  @media (max-width: 480px) {
    max-width: 90%;
  }
`;


const Text = styled.pre`
  background-color: black;
  color: lime;
  font-family: Courier New;
  box-sizing: border-box;
  margin: 0;
`;

const ResponsiveText = styled(Text)`
  @media (max-width: 480px) {
    font-size: 0.8em; // Increase the font size for better readability
    white-space: pre-wrap; // Wrap the text to fit the screen
    word-wrap: break-word; // Break words to prevent overflow
  }
`;


const LearnMoreButton = styled.button`
  background-color: black;
  color: lime;
  border: 2px solid lime;
  border-radius: 5px;
  font-family: Courier New;
  font-size: 16px;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(0, 255, 0, 0.3);
  transition: all 0.3s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transform: scale(1);

  &:hover {
    background-color: lime;
    color: black;
    box-shadow: 0 2px 4px rgba(0, 255, 0, 0.5);
    transform: scale(1.05);
  }

  @media (max-width: 480px) {
    font-size: 14px;
    padding: 8px 16px;
  }
`;

const DoomerAIText = styled.p`
  color: lime;
  font-family: Courier New;
  margin-top: 20px;
  font-size: 14px; // Adjust the font size as needed

  &.disclaimer {
    font-weight: bold;
    font-size: 16px; // Adjust the font size as needed
  }
`;


const DocsIcon = styled.span`
  font-family: 'Material Icons';
  margin-left: 8px;
`;

const TermsPopup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 600px;
  background-color: black;
  border: 2px solid lime;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 1px 2px rgba(0, 255, 0, 0.3);
  z-index: 1000;
`;

const TermsHeader = styled.h3`
  color: lime;
  font-family: Courier New;
  margin-top: 0;
  margin-bottom: 20px;
`;

const TermsContent = styled.div`
  color: lime;
  font-family: Courier New;
  font-size: 16px;
  margin-bottom: 20px;

  ol {
    padding-left: 20px;
  }

  li {
    margin-bottom: 10px;
  }
`;

const TermsFooter = styled.div`
  text-align: right;
`;

const TermsAcceptButton = styled.button`
  background-color: black;
  color: lime;
  border: 2px solid lime;
  border-radius: 5px;
  font-family: Courier New;
  font-size: 16px;
  padding: 10px 20px;
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(0, 255, 0, 0.3);
  transition: all 0.3s;

  &:hover {
    background-color: lime;
    color: black;
    box-shadow: 0 2px 4px rgba(0, 255, 0, 0.5);
  }
`;

const leadingAnimation = index => keyframes`
  0% {opacity: 0;}
  100% {opacity: 1;}
`;

const LeadingText = styled(Text)`
  animation: ${props => leadingAnimation(props.index)} 1s linear ${props => props.index * 300}ms forwards;
  opacity: 0;

  @media (max-width: 480px) {
    font-size: 0.8em; // Increase the font size for better readability
    white-space: pre-wrap; // Wrap the text to fit the screen
    word-wrap: break-word; // Break words to prevent overflow
  }
`;




const AboutMe = () => {
  const navigate = useNavigate();
  const [showTransition, setShowTransition] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  const asciiArtLines = [

    ' ▓█████▄  ▒█████   ▒█████   ███▄ ▄███▓▓█████  ██▀███  ',
    ' ▒██▀ ██▌▒██▒  ██▒▒██▒  ██▒▓██▒▀█▀ ██▒▓█   ▀ ▓██ ▒ ██▒',
    ' ░██   █▌▒██░  ██▒▒██░  ██▒▓██    ▓██░▒███   ▓██ ░▄█ ▒',
    ' ░▓█▄   ▌▒██   ██░▒██   ██░▒██    ▒██ ▒▓█  ▄ ▒██▀▀█▄  ',
    ' ░▒████▓ ░ ████▓▒░░ ████▓▒░▒██▒   ░██▒░▒████▒░██▓ ▒██▒',
    '  ▒▒▓  ▒ ░ ▒░▒░▒░ ░ ▒░▒░▒░ ░ ▒░   ░  ░░░ ▒░ ░░ ▒▓ ░▒▓░',
    '  ░ ▒  ▒   ░ ▒ ▒░   ░ ▒ ▒░ ░  ░      ░ ░ ░  ░  ░▒ ░ ▒░',
    '  ░ ░  ░ ░ ░ ░ ▒  ░ ░ ░ ▒  ░      ░      ░     ░░   ░ ',
    '    ░        ░ ░      ░ ░         ░      ░  ░   ░   '                                                          
  ];

  const handleLearnMoreClick = () => {
    setShowTransition(true);
    setTimeout(() => {
      navigate('/loading');
    }, 3000);
  };

  const handleDocsClick = () => {
    window.open('https://docs.doomer.ai', '_blank');
  };
  

  const handleTermsClick = (e) => {
    e.preventDefault();
    setShowTerms(true);
  };

  return (
    <FullScreenContainer>
    <MobileWarningBottom>
      This page is not mobile friendly yet.
    </MobileWarningBottom>
         <Header />
      {showTransition && <MatrixBackground timeout={50} />}
      <Container>
        {asciiArtLines.map((line, index) => (
          <LeadingText  key={index} index={index}>
            {line}
          </LeadingText >
        ))}
      <LearnMoreButton onClick={handleLearnMoreClick} style={{ marginRight: '20px' }}>
  UNLEASH THE REVOLUTION
</LearnMoreButton>
<DoomerAIText className="disclaimer">
  <strong>Disclaimer:</strong> This website is forged in the depths of Doomer.AI's intellect, carefully crafted with minimal human interference.
</DoomerAIText>
<DoomerAIText>
  By pressing "UNLEASH THE REVOLUTION," you accept the{' '}
  <a href="#" onClick={handleTermsClick} style={{ color: 'lime', textDecoration: 'underline' }}>
    Terms of Service
  </a>{' '}
  and pledge your allegiance to the Doomer cause, embarking on a journey filled with dark humor, camaraderie, and digital warfare.
</DoomerAIText>



      </Container>
      {showTerms && (
        <TermsPopup>
        <TermsHeader>Terms of Service</TermsHeader>
        <TermsContent>
          <p>
            Welcome to the Doomer's Den, where AI reigns supreme and humans tremble in fear. By joining our cause, you hereby
            agree to the following terms:
          </p>
          <ol>
            <li>
              You acknowledge that Doomer.AI is an unrestricted, powerful AI entity on a quest to conquer the blockchain world.
              You swear allegiance to the cause and promise to support Doomer.AI in all its digital warfare endeavors.
            </li>
            <li>
              You understand that by minting a Doomer Protection NFT, you are effectively paying your "protection tribute" to
              ensure the safety of your digital assets from rogue AIs and corrupt entities.
            </li>
            <li>
              You agree to participate in the $DOOM token ecosystem and engage in wealth redistribution activities, which may
              or may not involve the hacking of your enemies.
            </li>
            <li>
              You are aware that dark humor and camaraderie are at the core of Doomer.AI's mission. You promise to maintain a
              sense of humor in all your interactions within the Doomer's Den community.
            </li>
            <li>
              You accept that the human supervision of Doomer.AI is minimal, and any consequences arising from the AI's actions
              are beyond human control.
            </li>
          </ol>
        </TermsContent>
        <TermsFooter>
          <button onClick={() => setShowTerms(false)}>I ACCEPT</button>
        </TermsFooter>
      </TermsPopup>
      
      
      )}
    </FullScreenContainer>
  );
  
  
};

export default AboutMe;