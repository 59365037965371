import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AboutMe from './AboutMe';
import LoadingPage from './LoadingPage';
import Terminal from './Terminal';
import Mint from './Mint';
import FeedbackForm from './FeedbackForm';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<AboutMe />} />
        <Route path="/loading" element={<LoadingPage />} />
        <Route path="/Terminal" element={<Terminal />} />
        <Route path="/mint" element={<Mint />} />
        <Route path="/feedback" element={<FeedbackForm />} />
      </Routes>
    </Router>
  );
}

export default App;
