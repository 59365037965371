import React, { useEffect, useRef, useState } from 'react';
import './Terminal.scss';
import openai from 'openai';


const Terminal = () => {
  const codeRef = useRef(null);
  const [failedAttempts, setFailedAttempts] = useState(0);

// add function seperated later for all input text and content
  const rewriteText = async (inputText) => {
    try {
      const apiKey = 'XXXX'; // setup alternative
      openai.apiKey = apiKey;
  
      const response = await openai.Completion.create({
        engine: 'text-davinci-003',
        prompt: `Rewrite the following sentence in a more creative and engaging way: "${inputText}"`,
        temperature: 0.7,
        max_tokens: 50,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
      });
  
      if (response.choices && response.choices.length > 0) {
        return response.choices[0].text.trim();
      }
    } catch (error) {
      console.error(error);
      return inputText;
    }
  };
  
  useEffect(() => {
    const commands = String.raw`root@doomer.ai/:~ cd annihilation
    root@doomer.ai/annihilation/:~ ls`;

const beep = String.raw`



          _____                    _____                    _____                    _____                    _____          
         /\    \                  /\    \                  /\    \                  /\    \                  /\    \         
        /::\    \                /::\    \                /::\    \                /::\    \                /::\    \        
       /::::\    \              /::::\    \              /::::\    \              /::::\    \              /::::\    \       
      /::::::\    \            /::::::\    \            /::::::\    \            /::::::\    \            /::::::\    \      
     /:::/\:::\    \          /:::/\:::\    \          /:::/\:::\    \          /:::/\:::\    \          /:::/\:::\    \     
    /:::/__\:::\    \        /:::/__\:::\    \        /:::/__\:::\    \        /:::/__\:::\    \        /:::/  \:::\    \    
   /::::\   \:::\    \      /::::\   \:::\    \       \:::\   \:::\    \      /::::\   \:::\    \      /:::/    \:::\    \   
  /::::::\   \:::\    \    /::::::\   \:::\    \    ___\:::\   \:::\    \    /::::::\   \:::\    \    /:::/    / \:::\    \  
 /:::/\:::\   \:::\ ___\  /:::/\:::\   \:::\    \  /\   \:::\   \:::\    \  /:::/\:::\   \:::\    \  /:::/    /   \:::\ ___\ 
/:::/__\:::\   \:::|    |/:::/  \:::\   \:::\____\/::\   \:::\   \:::\____\/:::/__\:::\   \:::\____\/:::/____/     \:::|    |
\:::\   \:::\  /:::|____|\::/    \:::\  /:::/    /\:::\   \:::\   \::/    /\:::\   \:::\   \::/    /\:::\    \     /:::|____|
 \:::\   \:::\/:::/    /  \/____/ \:::\/:::/    /  \:::\   \:::\   \/____/  \:::\   \:::\   \/____/  \:::\    \   /:::/    / 
  \:::\   \::::::/    /            \::::::/    /    \:::\   \:::\    \       \:::\   \:::\    \       \:::\    \ /:::/    /  
   \:::\   \::::/    /              \::::/    /      \:::\   \:::\____\       \:::\   \:::\____\       \:::\    /:::/    /   
    \:::\  /:::/    /               /:::/    /        \:::\  /:::/    /        \:::\   \::/    /        \:::\  /:::/    /    
     \:::\/:::/    /               /:::/    /          \:::\/:::/    /          \:::\   \/____/          \:::\/:::/    /     
      \::::::/    /               /:::/    /            \::::::/    /            \:::\    \               \::::::/    /      
       \::::/    /               /:::/    /              \::::/    /              \:::\____\               \::::/    /       
        \::/____/                \::/    /                \::/    /                \::/    /                \::/____/        
         ~~                       \/____/                  \/____/                  \/____/                  ~~              
                                                                                                                             

                                                                                                                             
                            


# # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #`;

const samurai = String.raw`


________      ______      ______   ___      ___   _______   _______            __       _______   __     
|"      "\    /    " \    /    " \ |"  \    /"  | /"     "| /"      \          /""\     /" _   "| |" \    
(.  ___  :)  // ____  \  // ____  \ \   \  //   |(: ______)|:        |        /    \   (: ( \___) ||  |   
|: \   ) || /  /    ) :)/  /    ) :)/\\  \/.    | \/    |  |_____/   )       /' /\  \   \/ \      |:  |   
(| (___\ ||(: (____/ //(: (____/ //|: \.        | // ___)_  //      /       //  __'  \  //  \ ___ |.  |   
|:       :) \        /  \        / |.  \    /:  |(:      "||:  __   \      /   /  \\  \(:   _(  _|/\  |\  
(________/   \"_____/    \"_____/  |___|\__/|___| \_______)|__|  \___)    (___/    \___)\_______)(__\_|_)  `;


    const RandomNumber = (min, max) => {
      return Math.floor(Math.random() * max) + min;
    };

    const Delay = (time) => {
      return new Promise((resolve) => setTimeout(resolve, time));
    };

    const ResetTerminal = () => {
      codeRef.current.innerHTML = '<span class="blink">█</span>';
    };

    const RenderString = (characters) => {
      const blink = codeRef.current.querySelector('.blink');
      blink.insertAdjacentHTML('beforeBegin', characters);
    };

    const TypeString = async (characters) => {
      for (const character of characters.split('')) {
        await Delay(RandomNumber(30, 100));
        RenderString(character);
      }
    };


    const DrawLines = async (characters, min = 50, max = 100) => {
      for (const line of characters.split('\n')) {
        await Delay(RandomNumber(min, max));
        RenderString(`${line}\n`);
      }
    };

    const processRequest = async (isCorrect) => {
      await TypeString('\nReceiving request...\n');
      await Delay(400);
      await TypeString('Processing request...\n');
      await Delay(600);
      
      if (isCorrect) {
        await TypeString('Request successful. Weiterleitung in: ');
        for (let i = 3; i > 0; i--) {
          await TypeString(`${i}...`);
          await Delay(500);
        }
        window.location.href = '/mint';
      } else {
        setFailedAttempts(failedAttempts + 1);
        
        if (failedAttempts >= 2) {
          await TypeString('\nWarning: All your private keys will be exposed and your system is hacked!\n');
        } else {
          await TypeString('\nRequest denied by auto protection AI. Visit the documentation for more information: docs.doomer.ai');
        }
      }
    };
  
    const handleUserInput = async (input) => {
      if (input.trim().toLowerCase() === 'mint') {
        await processRequest(true);
      } else {
        await processRequest(false);
      }
    };
    
  
    const showUserInputPrompt = () => {
      const inputField = document.createElement('input');
      inputField.className = 'user-input';
      inputField.style.width = '100%';
      inputField.style.color = '#00FF00';
      inputField.style.background = 'black';
      inputField.style.border = 'none';
      inputField.style.outline = 'none';
      inputField.style.fontFamily = 'monospace';
      inputField.placeholder = 'Enter command...';
      inputField.style.fontSize = '3.1em';
      inputField.style.animation = 'blink 0.5s step-end infinite alternate';
      
      const countdown = document.createElement('div');
      countdown.className = 'countdown';
      countdown.style.color = 'red';
    
      codeRef.current.appendChild(inputField);
      codeRef.current.appendChild(countdown);
    
      let timeLeft = 90;
  countdown.innerText = `Time left: ${timeLeft}s`;
  const timer = setInterval(() => {
    timeLeft -= 1;
    countdown.innerText = `Time left: ${timeLeft}s`;
  
    if (timeLeft <= 80) {
      countdown.style.color = 'yellow';
      countdown.style.textShadow = '0 0 5px #FFFF00';
    }
  
    if (timeLeft <= 70) {
      countdown.style.color = 'orange';
      countdown.style.textShadow = '0 0 10px #FFA500';
    }
  
    if (timeLeft <= 60) {
      countdown.style.color = 'orangered';
      countdown.style.textShadow = '0 0 15px #FF4500';
      countdown.style.animation = 'pulse 1s infinite';
    }
  
    if (timeLeft <= 50) {
      countdown.style.color = 'red';
      countdown.style.textShadow = '0 0 20px #FF0000';
      countdown.style.animation = 'pulse 0.5s infinite';
    }
  
    if (timeLeft <= 20) {
      countdown.style.color = 'red';
      countdown.style.fontWeight = 'bold';
    }
  
    if (timeLeft <= 10) {
      countdown.style.color = 'red';
      countdown.style.animation = 'blink 1s step-end infinite alternate';
    }
  
    if (timeLeft <= 0) {
      clearInterval(timer);
      handleUserInput('');
    }
  }, 1000);
  
      
    
      inputField.addEventListener('keydown', (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          clearInterval(timer);
          handleUserInput(inputField.value);
        }
      });
    
      inputField.focus();
    };

    const DrawCommands = async (commands) => {
      for (const line of commands.split('\n')) {
        const [currentDir, command] = line.split(':~ ');
        RenderString(`${currentDir}:~ `);
        await TypeString(command);
        RenderString('\n');
        await Delay(RandomNumber(100, 150));
      }
    };

    (async () => {
      await DrawCommands("/:~ ssh root@doomer.ai -p 2000");
      await Delay(50);
      RenderString("root@doomer.ai password:");
      await Delay(2000);
      RenderString("\n");
      await DrawCommands(commands);
      RenderString('\nbased.py    doomer.py\n\n');
      await DrawCommands('root@doomer.ai/annihilation:~ python based.py');
      await DrawLines(beep);
      const originalText = "Ah, a fellow traveler in the digital wasteland. Care to join our cryptic crusade? Pay heed to the final act.";
      const newText = await rewriteText(originalText);
      await TypeString(`\n\n${newText}`);
  
      await Delay(3000);
      ResetTerminal();
      await DrawCommands('root@doomer.ai:~ KEY=3db7ca618243da1ba3bc76ab14bcf07b python doomer.py');
      await DrawLines(samurai);
      await TypeString('\nroot@doomer.ai:~ ');
      showUserInputPrompt();
    })();
  }, []);



  

  
  return (
    <pre className="code" ref={codeRef}>
      <span className="blink">█</span>
    </pre>
  );
};

export default Terminal;
