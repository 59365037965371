import { useState, useEffect } from 'react';
import Web3 from 'web3';

export const useWeb3 = () => {
  const [web3, setWeb3] = useState(null);
  const [account, setAccount] = useState(null);

  const connectWallet = async () => {
    if (window.ethereum) {
      const newWeb3 = new Web3(window.ethereum);
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const accounts = await newWeb3.eth.getAccounts();
        setAccount(accounts[0]);
        setWeb3(newWeb3);
        return newWeb3;
      } catch (error) {
        console.error('Error connecting to wallet:', error);
      }
    } else {
      console.error('Ethereum not detected in the browser');
    }
  };
  
  
  

  useEffect(() => {
    if (web3 && account) {
      const updateAccount = async () => {
        const accounts = await web3.eth.getAccounts();
        setAccount(accounts[0]);
      };

      window.ethereum.on('accountsChanged', updateAccount);

      return () => {
        window.ethereum.removeListener('accountsChanged', updateAccount);
      };
    }
  }, [web3, account]);

  return { web3, account, connectWallet, setWeb3 };
};
