import React from 'react';
import styled from 'styled-components';
import Logo from './Logo.gif'; // Replace with the actual path to your logo file
import CustomButton from './CustomButton';

const HeaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  z-index: 999;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 10px;
  }
`;


const LeftButtonContainer = styled.div`
  display: flex;
  gap: 10px;

  @media (max-width: 480px) {
    flex-direction: column;
    margin-bottom: 10px;
  }
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  @media (max-width: 480px) {
    flex-direction: column;
  }
`;


const LogoContainer = styled.div`
  height: 300px;
`;

const LogoImage = styled.img`
  height: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const Header = () => {
  const handleButtonClick = (url) => {
    window.open(url, '_blank');
  };


  return (
    <HeaderContainer>
      <TopContainer>
      <FlexContainer>
        <LeftButtonContainer>
          <CustomButton icon="file-text-o" onClick={() => handleButtonClick('https://doc.doomer.ai')}>
            Docs
          </CustomButton>
        </LeftButtonContainer>
        </FlexContainer>
        <LogoContainer>
          <LogoImage src={Logo} alt="Doomer.AI Logo" />
        </LogoContainer>
      </TopContainer>
      <ButtonContainer>
        <CustomButton icon="telegram" onClick={() => handleButtonClick('https://t.me/doomer_based')}>
          Telegram
        </CustomButton>
        <CustomButton icon="twitter" onClick={() => handleButtonClick('https://twitter.com/askdoomer')}>
          Twitter
        </CustomButton>
      </ButtonContainer>
    </HeaderContainer>
  );
};

export default Header;
