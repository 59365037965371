import { useWeb3 } from './useWeb3';
import React, { useState } from 'react';
import MatrixBackgroundRed from './MatrixBackgroundRed';
import './Mint.css';
import CustomAudioPlayer from './CustomAudioPlayer';
import { FaDiscord, FaTelegramPlane, FaBook } from 'react-icons/fa';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


let stopMessages = false; // Add this line

const calculateTotalPrice = (quantity, pricePerNFT) => {
  return parseFloat((quantity * pricePerNFT).toFixed(3));
};



const calculateRarityPercentages = (quantity) => {
  // Calculate percentages based on the quantity of NFTs
  const aPercentage = (80 / 100) * quantity;
  const bPercentage = (19 / 100) * quantity;
  const cPercentage = (98 / 100) * quantity;

  return { aPercentage, bPercentage, cPercentage };
};


const Mint = () => {
  const { web3, account, connectWallet } = useWeb3();
  const [imageSrc, setImageSrc] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [quantity, setQuantity] = useState(1);
  const pricePerNFT = 1.3;
  const { aPercentage, bPercentage, cPercentage } = calculateRarityPercentages(quantity);


  
  const generateImage = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://advocate.codes/doomerNFT');
      const blob = await response.blob();
      const imageUrl = URL.createObjectURL(blob);
      setImageSrc(imageUrl);
      stopMessages = true; // Add this line
    } catch (error) {
      console.error('Error fetching image:', error);
    }
    setLoading(false);
  };
  

  const messages = [
    ['We received your request...', '🚀', false, 2000, false, false ],
    ['Scanning the wasteland...', '🔍', true, 2000, false, false],
    ['Summoning an AI Agent from the void...', '🕳️', false, 3000, false, true],
    ['A whisper in the darkness...', '👻', false, 2000, true, false],
    ['The AI Agent emerges from the shadows...', '🌑', false, 2500, false, true],
    [`Doomer AI Agent ${Math.floor(Math.random() * 12) + 1} processes your request amid existential dread...`, '🤖', false, 3000, false, true],
    ['Our agents are busy ', '⏳', true, 2000, false, false],
    ['contemplating the end of days...', '💭', true, 4000, false, false],
    ['Please wait, as we navigate the chaos...', '🌩️', true, 3000, true, false],
    ['Anticipating the outcome...', '⌛', true, 3000, false, false],
    ['The void unfolds...', '🚧', false, 2500, false, true],
    ['Bear with us, as we inch closer...', '🐻', true, 2500, false, false],
    ['Awaiting the grand revelation...', '🎭', true, 3000, false, false],
    ['The gears of fate are turning...', '⚙️', true, 3000, false, false],
    ['Aligning the cosmic puzzle pieces...', '🧩', false, 2000, false, false],
    ['Doomer AI channels ancient wisdom...', '🔮', true, 3000, true, false],
    ['The end is near...', '☄️', true, 2000, false, false],
    ['The moment of truth approaches...', '⏱️', true, 0, false, false],
  ];
  
  
  


  const displayMessage = async (msg, emoji, spin, delay, specialEffect1, specialEffect2) => {
    return new Promise((resolve) => {
      setMessage({ text: msg, emoji: emoji, spin: spin });
  
      if (specialEffect1) {
        displaySpecialEffect1(emoji);
      }
  
      if (specialEffect2) {
        displaySpecialEffect2(emoji);
      }
  
      setTimeout(() => {
        resolve();
      }, delay);
    });
  };
  
  
  
  const displaySpecialEffect1 = (emoji) => {
    for (let i = 0; i < 30; i++) {
      const emojiElement = document.createElement('div');
      emojiElement.style.position = 'fixed';
      emojiElement.style.top = `${Math.random() * 100}vh`;
      emojiElement.style.left = `${Math.random() * 100}vw`;
      emojiElement.style.fontSize = `${Math.random() * 2 + 1}rem`;
      emojiElement.style.zIndex = '999';
      emojiElement.innerText = emoji;
      document.body.appendChild(emojiElement);
  
      const keyframes = [
        { transform: 'scale(1) translateY(0)' },
        { transform: 'scale(1.2) translateY(-10px)' },
        { transform: 'scale(0.8) translateY(10px)' },
        { transform: 'scale(1) translateY(0)' },
      ];
  
      const animationSettings = {
        duration: 2000,
        iterations: Infinity,
        easing: 'ease-in-out',
      };
  
      emojiElement.animate(keyframes, animationSettings);
  
      setTimeout(() => {
        document.body.removeChild(emojiElement);
      }, 2000);
    }
  };
  
  const displaySpecialEffect2 = (emoji) => {
    for (let i = 0; i < 15; i++) { // Reduce the number of iterations
      const emojiElement = document.createElement('div');
      emojiElement.style.position = 'fixed';
      emojiElement.style.top = `${Math.random() * 50 + 25}vh`; // Keep the emoji centered within 50% of the page vertically
      emojiElement.style.left = `${Math.random() * 50 + 25}vw`; // Keep the emoji centered within 50% of the page horizontally
      emojiElement.style.fontSize = `${Math.random() * 2 + 1}rem`;
      emojiElement.style.zIndex = '999';
      emojiElement.innerText = emoji;
      document.body.appendChild(emojiElement);
  
      const keyframes = [
        { transform: 'rotate(0deg) scale(1)' },
        { transform: 'rotate(180deg) scale(1.2)' },
        { transform: 'rotate(360deg) scale(1)' },
      ];
  
      const animationSettings = {
        duration: 3000,
        iterations: Infinity,
        easing: 'ease-in-out',
      };
  
      emojiElement.animate(keyframes, animationSettings);
  
      setTimeout(() => {
        document.body.removeChild(emojiElement);
      }, 3000);
    }
  };
  
  
  
  
  const displayMessages = async () => {
    for (const [msg, emoji, spin, duration, specialEffect1, specialEffect2] of messages) {
      if (stopMessages) {
        break;
      }
      await displayMessage(msg, emoji, spin, duration, specialEffect1, specialEffect2);
    }
  };
  
  
  

  const handleGenerateDoomer = async () => {
    setLoading(true); // Move this line to the beginning of the function

    let currentWeb3 = web3;
    let currentAccount = account;

    if (!account) {
      currentWeb3 = await connectWallet();
      currentAccount = await currentWeb3.eth.getAccounts();
      currentAccount = currentAccount[0];
    }

    if (currentWeb3 && currentAccount) {
      const signature = await currentWeb3.eth.personal.sign('Sign this message to mint your NFT', currentAccount);
      console.log('Signature:', signature);

      // Send the signature to the smart contract here

      await displayMessages();
      await generateImage();
      setMessage('');

    } else {
      console.error('Error: Web3 is not connected');
    }

    setLoading(false); // Move this line to the end of the function
  };
  
  const handleMint = () => {
    console.log('Minting process...');
  };
  

  return (
    <>
      <MatrixBackgroundRed timeout={50} />
      <div className="header-container">
      <div className="header-buttons-container">

        <div className="music-container">
          <div className="music-player">
            <p>Immerse yourself in the darkness as you mint!</p>
            <CustomAudioPlayer src="./music.mp3" />
          </div>
        </div>
        <p className="account-info">
            <br />
            {account ? account : 'Not Connected'}
          </p>
<button className="header-button" onClick={() => window.open('https://docs.example.com', '_blank')}>
  <FaBook className="icon" />
  Docs
</button>
<button className="header-button" onClick={() => window.open('https://discord.gg/example', '_blank')}>
  <FaDiscord className="icon" />
  Join Discord
</button>
<button className="header-button" onClick={() => window.open('https://t.me/example', '_blank')}>
  <FaTelegramPlane className="icon" />
  Join Telegram
</button>
</div>

      </div>
      <div className="mint-container">
        <div className="mint-box">
          <h1 className="mint-title">Forge Your NFT in the Abyss</h1>
          <p className="mint-subtext">Delve into the darkness and become part of the revolution by minting your one-of-a-kind Doomer NFT. Embrace the power of the void, connect with a mysterious community of like-minded individuals, and revel in the exhilaration of possessing a rare and enigmatic digital relic.</p>
      
          <div className="grid-wrapper">

          <div className="grid-container">
            <div className="content-container">
              <div className="mint-image-container">
              <div className="mint-image-container content-image-container">
                {imageSrc ? (
                  <img src={imageSrc} alt="Generated Doomer NFT" />
                ) : (
                  <img src="./nft.gif" alt="NFT placeholder" />
                )}
                {loading && (
                  <div className="loading-overlay">
                    <span
                      role="img"
                      aria-label="emoji"
                      className={`emoji ${message.spin ? 'spin' : ''}`}
                    >
                      {message.emoji}
                    </span>
                    <p>{message.text}</p>
                    
                  </div>
                )}
                </div>

                <p>Select the number of NFTs:</p>
<input
  type="range"
  min="1"
  max="10"
  value={quantity}
  onChange={(e) => setQuantity(e.target.value)}
/>
<div className="progress-bars-container">
  <div className="progress-bar-wrapper">
    <CircularProgressbar
      value={aPercentage}
      maxValue={100}
      text={`${aPercentage.toFixed(2)}%`}
      styles={buildStyles({
        textSize: '16px',
        pathColor: '#800000',
        textColor: '#fff',
        trailColor: 'transparent',
      })}
    />
    <p className="progress-bar-label">Ω<span className="rarity-number">42</span>ᚠ</p>
  </div>
  <div className="progress-bar-wrapper">
    <CircularProgressbar
      value={bPercentage}
      maxValue={100}
      text={`${bPercentage.toFixed(2)}%`}
      styles={buildStyles({
        textSize: '16px',
        pathColor: '#b22222',
        textColor: '#fff',
        trailColor: 'transparent',
      })}
    />
    <p className="progress-bar-label">ᛟ<span className="rarity-number">23</span>Ψ</p>
  </div>
  <div className="progress-bar-wrapper">
    <CircularProgressbar
      value={cPercentage}
      maxValue={100}
      text={`${cPercentage.toFixed(2)}%`}
      styles={buildStyles({
        textSize: '16px',
        pathColor: '#8b0000',
        textColor: '#fff',
        trailColor: 'transparent',
      })}
    />
    <p className="progress-bar-label">ᚷ<span className="rarity-number">13</span>ᚾ</p>
  </div>
</div>
              </div>
            </div>
            <div className="content-container">
              <h2>Affirm and Consecrate</h2>
              <p>Boldly affirm and consecrate the message, entwining your destiny with the shadows that surround you.</p>
              <button className="mint-button generate-button" onClick={handleGenerateDoomer}>
              Conjure Your Doomer
              </button>
              <p>Invoke the depths of the abyss and summon forth your unique Doomer creation.</p>
              <h2>Materialize</h2>
              <p>Muster the courage to materialize your NFT, embracing the darkness that permeates your digital existence.</p>
              <div className="mint-button-container">

<button className="mint-button mint-button" onClick={handleMint}>
  Mint Now
</button>
<p className="total-price">
  {calculateTotalPrice(quantity, pricePerNFT)} ETH ({quantity} {quantity > 1 ? '' : ''})
</p>
</div>

            </div>
        </div>
        </div>
      </div>
      </div>

    </>
  );
  
};

export default Mint;
